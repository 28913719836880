import loadConfig from '@/plugins/loadConfig';
import createVueRouter from "@/router/createVueRouter";
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/router'
import '@/scss/main.scss'
import '@/plugins/notifications';

import('@/plugins/bugsnag')
import('@/plugins/vuelidate')
import('@/utils/filters')

Vue.config.productionTip = false

loadConfig().then(() => {
    const router = createVueRouter();

    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
});
