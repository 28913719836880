import LocalStorageService from "@/utils/Services/LocalStorageService";
import VueRouter from "vue-router";
import qs from "qs";
import UrlParameterService from "@/utils/Services/UrlParameterService";

function guardMyRoute(to, from, next) {
    if (!LocalStorageService.get('token')) {
        next('/login');
    } else {
        next();
    }
}

function routes() {
    if (window.config.READ_ONLY === true) {
        return [
            {
                path: '*',
                props: true,
                component: () => import('@/views/MaintenanceModeView.vue'),
            },
        ];
    }

    return [
        {
            path: '/',
            name: 'home',
            props: true,
            beforeEnter : guardMyRoute,
            component: () => import('@/views/Dossier/index.vue'),
        },
        {
            path: '/agent/:agentHash/dossier/:dossierHash',
            component: () => import('@/views/Agent/DossierView.vue'),
            children: [
                {
                    path: '',
                    redirect: 'product'
                },
                {
                    path: 'product',
                    component: () => import('@/components/Agent/Dossier/Product/index.vue'),
                },
                {
                    path: 'proposal',
                    component: () => import('@/components/Agent/Dossier/Proposal/index.vue'),
                },
                {
                    path: 'attachment',
                    component: () => import('@/components/Agent/Attachment/index.vue'),
                },
                {
                    path: 'traveller',
                    component: () => import('@/components/Agent/Dossier/Traveller/index.vue'),
                },
                {
                    path: 'timeline',
                    component: () => import('@/components/Agent/Dossier/Timeline/index.vue'),
                },
            ],
        },
        {
            path: '/all-orders',
            props: true,
            component: () => import('@/views/Dossier/AllOrders.vue'),
        },
        {
            path: '/login',
            props: true,
            component: () => import('@/views/Agent/Login.vue'),
        },
        {
            path: '/request-password',
            props: true,
            component: () => import('@/views/Agent/RequestPassword.vue'),
        },
        {
            path: '/reset-password/:token',
            props: true,
            component: () => import('@/views/Agent/NewPassword.vue'),
        },
        {
            path: '/404',
            props: true,
            component: () => import('@/views/NotFoundView.vue'),
        },
        {
            path: '*',
            props: true,
            component: () => import('@/views/NotFoundView.vue'),
        },
    ];
}

export default function createVueRouter() {
    return new VueRouter({
        routes: routes(),
        mode: 'history',
        parseQuery(queryString) {
            let object = qs.parse(queryString);

            return UrlParameterService.formatQueryParameters(object);
        },
        stringifyQuery(object) {
            const result = qs.stringify(object);
            return result ? ('?' + result) : '';
        }
    });
}
